<template lang="pug">
	#section-reassign-queue-list-1
		el-row
			el-col(:span="24")
				el-row(:gutter="48")
					el-col(:xs="24")
						.text-left
							h5 List Of leads in queues
							p This is the list of leads that are currently in the queue for reassignment/Re-Register (Reassign to the same Brand/Funnel that the lead is currently a part of). You can remove the lead from the queue by clicking the delete button.&nbsp;
								| The filters support filtering multiple values at once - just add a comma between the id/cid/email of the lead or the uuid of the entry. You can select multiple brand/funnel options (source/destination) as well.&nbsp;
					el-col.text-left(:xs="24" :sm="12")
						p(v-if="filters.grouping") <b>Selected Queue Group</b>: {{ filters.grouping  }}.
					el-col.text-left(:xs="24" :sm="12")
						p(v-if="filters.grouping") <b>Group Destination</b>: Brand - {{ grouping_brands }}, Funnel - {{ grouping_funnels }}
		el-form
			el-row.mt-3(:gutter="12")
				el-col(:xs="12" :sm="6" :md="4")
					el-form-item(label="By Keyword" label-position="top" label-width="auto")
						el-input(type="text", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData")


				//el-col(:xs="12" :sm="6" :md="4") =======By Groups - Second part of the task=========
				el-col(:xs="12" :sm="6" :md="4")
					el-form-item(label="By Queue Group" label-position="top" label-width="auto")
						el-select.w-100(filterable="", collapse-tags="", v-model="filters.grouping", @change="getGroupingDetails")
							el-option(value="" label="- Select Queue Group -" )
							el-option(v-for="o of options.queue_groups"  :value="o.v" :label="o.t")

				el-col(:xs="12" :sm="6" :md="4")
					el-form-item(label="By Source Brand" label-position="top" label-width="auto")
						el-select.w-100(filterable="", multiple="" collapse-tags="" v-model="filters.source_brand_id", @change="fetchData")
							el-option(value="" label="- Select Source Brand -" )
							el-option(v-for="o of brands_options"  :value="o.v" :label="o.t")
				el-col(:xs="12" :sm="6" :md="4")
					el-form-item(label="By Destination Brand" label-position="top" label-width="auto")
						el-select.w-100(filterable="", multiple="" collapse-tags="" v-model="filters.dest_brand_id", @change="fetchData")
							el-option(value="" label="- Select Destination Brand -" )
							el-option(v-for="o of brands_options"  :value="o.v" :label="o.t")
				el-col(:xs="12" :sm="6" :md="4")
					el-form-item(label="By Source Funnel" label-position="top" label-width="auto")
						el-select.w-100(filterable="", multiple="" collapse-tags="" v-model="filters.source_funnel_id", @change="fetchData")
							el-option(value="" label="- Select Source Funnel -" )
							el-option(v-for="o of funnels_options"  :value="o.v" :label="o.t + (o.nt ? ` (${o.nt})` : '')")
				el-col(:xs="12" :sm="6" :md="4")
					el-form-item(label="By Destination Funnel" label-position="top" label-width="auto")
						el-select.w-100(filterable="", multiple="" collapse-tags="" v-model="filters.dest_funnel_id", @change="fetchData")
							el-option(value="" label="- Select Destination Funnel -" )
							el-option(v-for="o of funnels_options"  :value="o.v" :label="o.t + (o.nt ? ` (${o.nt})` : '')")

			el-divider
			.d-flex.justify-content-between
				.text-left
					h5 Bulk Functions:&nbsp;
				div
					el-tooltip(content="Bulk Clear Logs")
						el-button(size="mini" type="warning" :disabled="!filters.grouping" @click="bulkDeleteByGroup()") Clear Group
					el-tooltip(content="Bulk Clear Logs")
						el-button(size="mini" type="danger" :disabled="multipleSelection.length === 0" @click="bulkDeleteEntries()") Selection Delete
		el-row.mt-3
			el-col
				el-table(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='' @selection-change="handleSelectionChange")
					el-table-column(type="selection" :width="55"  )
					el-table-column(align="center" label="UUID" :width="260" )
						template(slot-scope='scope')
							| {{ scope.row.uuid }}
					el-table-column(align="center" label="Group")
						template(slot-scope='scope')
							| {{ scope.row.grouping }}
					el-table-column(align="center")
						template(slot="header")
							label Last Exec.
							el-tooltip(content="Last execution time in the queue")
								el-icon.chooseable(name="question")
						template(slot-scope='scope')
							| {{ scope.row.last_attempt_formatted || '-' }}
					el-table-column(align="center" label="Next Exec.")
						template(slot="header")
							label Next Exec.
							el-tooltip(content="This is the estimated time while the next execution in the queue would be prepared. Notice - it is not the time when the lead will be reregistered")
								el-icon.chooseable(name="question")
						template(slot-scope='scope')
							| {{ scope.row.next_attempt_formatted || '-' }}
					el-table-column(align="center" label="E-Mail / ID" :width="280")
						template(slot-scope='scope')
							span {{ scope.row.lead.email }}&nbsp;
							small.text-success ({{ scope.row.lead.id }})
					el-table-column(align="center" label="Brand")
						template(slot-scope='scope')
							span.d-block <b>Original:</b> {{ scope.row.lead && scope.row.lead.brand ? scope.row.lead.brand.name : '-'  }}
							span.d-block <b>Target:</b> {{ scope.row.dest_brand.name }}
					el-table-column(align="center" label="Funnel")
						template(slot-scope='scope')
							span.d-block <b>Original:</b> {{ scope.row.lead && scope.row.lead.funnel ? scope.row.lead.funnel.name : '-' }}
							span.d-block <b>Target:</b> {{ scope.row.dest_funnel.name }}
					el-table-column(align="center" label="Retries" :width="65")
						template(slot-scope="scope")
							span(v-bind:class="{'text-danger': scope.row.retries> 2}") {{ scope.row.retries }}
					el-table-column(align="center" label="Actions" :width="100")
						template(slot-scope='scope')
							el-button(size="mini" icon="el-icon-delete" type="danger" @click="removeEntry(scope.row.uuid, scope.row.lead.id)")

					// todo add a tabl for queues, add buttons "pause", "resume", "stop"
		.d-flex.justify-content-between
			b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

			el-row.mt-4
				el-col(:span="24")
					label.label-text-gray
						b Total:&nbsp;
						| {{ count | numeral('0,0') }}

			el-row
				el-col(:span="24")
					el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
						el-option(label="10 Per Page", :value="10")
						el-option(label="20 Per Page", :value="20")
						el-option(label="50 Per Page", :value="50")
						el-option(label="100 Per Page", :value="100")
						el-option(label="200 Per Page", :value="200")
</template>

<style lang="scss">

</style>

<script lang="js">
export default {
	name: 'reassign-queue',
	props: [
		'brands_options',
		'funnels_options',
		],
	data() {
		return {
			fetchWatcher: null,
			busy: false,
			list: [],
			count: 0,
			paginate: {
				page: 1,
				keyword: '',
				limit: 10
			},
			filters: {
				source_brand_id: '',
				dest_brand_id: '',
				source_funnel_id: '',
				dest_funnel_id: '',
				grouping: ''
			},
			options: {
				queue_groups: []
			},
			grouping_brands: [],
			grouping_funnels: [],
			multipleSelection: [],
			block: false
		}
	},
	mounted() {
		this.fetchData().then();
		this.fetchWatcher = setInterval(() => {
			this.fetchData().then();
		}, 30000);
		this.getOptions();
	},
	beforeDestroy() {
		clearInterval(this.fetchWatcher)
	},
	methods: {
		bulkDeleteEntries() {
			const that = this;
			this.$confirm(`Are you sure that you want to this action on the selected entries? This operation cannot be undone!`, 'Bulk delete Alert')
					.then(async () => {
						that.busy = true;
						const result = await that.$apix.sendHttpRequest('POST', 'lead/remove-from-queue-bulk', { list: that.multipleSelection.map(e => e.uuid) });
						that.$notify.success({
							title: 'API Response',
							message: 'The selected entries have been deleted successfully'
						});
						await that.fetchData();
						that.busy = false;
					})
					.catch(err => {
						console.error(err);
						that.busy = false;
					})
		},
		bulkDeleteByGroup() {
			const that = this;
			this.$confirm(`Are you sure that you want to this action? It will clear all the entries that are relevant to the selected group and the group itself. This operation cannot be undone!`, 'Bulk delete Alert')
					.then(async () => {
						that.busy = true;
						const result = await that.$apix.sendHttpRequest('DELETE', 'lead/queue-group/' + that.filters.grouping);
						that.$notify.success({
							title: 'API Response',
							message: 'The group has been cleared successfully'
						});
						await that.fetchData();
						that.busy = false;
					})
					.catch(err => {
						console.error(err);
						that.busy = false;
					})
		},
		getGroupingDetails() {
			if(this.filters.grouping)
				this.$apix.sendHttpRequest('GET', 'lead/queue/entity-by-grouping/' + this.filters.grouping)
					.then(res => {
						this.grouping_brands = res.brand;
						this.grouping_funnels = res.funnel;
					})
			this.fetchData();
		},
		getOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', {filters: 'c9e2b7e1-2bad-8b7f-953d-9b144b4f003d'})
				.then(options => {
					this.options.queue_groups = options.queue_groups;
				});
		},
		handleSelectionChange(val) {
			this.block =  val.length > 0
			this.multipleSelection = val;
		},
		async fetchData() {
			try {
				this.busy = true;
				setTimeout(async () => {
					let $paginate = {...this.paginate, ...this.filters};
					let res = await this.$apix.sendHttpRequest('GET', 'lead/queue-list', $paginate)
					this.list = res.rows;
					this.count = res.count;
					this.busy = false;
				}, 0)
			} catch (e) {
				console.error('[ERROR] Reassign Queue: ', e);
				this.busy = false;
			}
		},
		removeEntry(uuid, lead_id) {
			this.$confirm('Are you sure that you want to delete this entry? This operation cannot be undone!', 'Lead Reassign Discard Alert')
				.then(async () => {
					const result = await this.$apix.sendHttpRequest('DELETE', 'lead/remove-from-queue', { uuid, lead_id })
					await this.fetchData();
				})
				.catch(err => {
					console.error('[ERROR] Reassign Queue remove entry', err);
				})
		},
		permitColumn(columns, strict = true) {
			if(this.$store.getters.ACL === 'all') return true;
			let permittedCount = columns.length;
			for(const col of columns) {
				if(!this.$store.getters.ACL[col])
					if(strict)
						return false;
					else
						permittedCount--;
				if(permittedCount===0) return false;
			}
			return true;
		},
	}
}
</script>
